<template>
    <div id="dashboard-section" class="mt-sm-5">
        <div class="container">
            <div class="flex-column flex-sm-row justify-content-between row">
                <!-- User Info-->
                <div class="bg-gray rounded p-4 user-info">
                    <div class="row">
                        <div class="col-8">
                            <h2 class="text-primary mb-3">Welcome <span>{{ user.name }}</span></h2>
                            <div class="d-flex">
                                <div class="text-primary mb-3 type fw-bold">Name:</div>
                                <div class="text-dark">{{ user.name }}</div>
                            </div>
                            <div class="d-flex">
                                <div class="text-primary mb-3 type fw-bold">Mobile:</div>
                                <div class="text-dark">{{ user.phone }}</div>
                            </div>
                            <div class="d-flex">
                                <div class="text-primary mb-3 type fw-bold">Email:</div>
                                <div class="text-dark">{{ user.email }}</div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="avatar float-end overflow-hidden">
                                <img class="img-thumbnail h-100 p-2" :src="user.image"
                                     alt="logo">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="d-flex">
                                <div class="text-primary mb-3 type fw-bold">Address:</div>
                                <div class="text-dark">{{ user.address }}</div>
                            </div>
                        </div>

                        <!-- login entry-->
                        <div class="col-12">
                            <form @submit.prevent="addAttendance()">
                                <div class="d-flex mt-5 mb-3">
                                    <div class="text-primary mb-3 type fw-bold">Date:</div>
                                    <div class="text-dark row">
                                        <div class="col-md-12">
                                            {{ form.date | dateFormat }}
                                            <!--                                            <DatePicker :class_name="{ 'is-invalid': errors['date'] }" title="Date"
                                                                                                    v_model="date"/>
                                                                                        <div v-if="errors['date']" class="invalid-feedback">
                                                                                            {{ errors['date'][0] }}
                                                                                        </div>-->
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex mb-3">
                                    <div class="text-primary mb-3 type fw-bold">Login:</div>
                                    <div class="text-dark row">
                                        <div class="col-6">
                                            <TimePicker :class_name="{ 'is-invalid': errors['log_in'] }" title="Login"
                                                        v_model="log_in"/>
                                            <div v-if="errors['log_in']" class="invalid-feedback">
                                                {{ errors['log_in'][0] }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex mb-3">
                                    <div class="text-primary mb-3 type fw-bold">Logout:</div>
                                    <div class="text-dark row">
                                        <div class="col-6">
                                            <TimePicker :class_name="{ 'is-invalid': errors['log_out'] }" title="Logout"
                                                        v_model="log_out"/>
                                            <div v-if="errors['log_out']" class="invalid-feedback">
                                                {{ errors['log_out'][0] }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div class="text-primary mb-3 type fw-bold"></div>
                                    <div class="text-dark row">
                                        <div class="col-12">
                                            <input type="submit" value="Save"
                                                   class="btn btn-primary mb-sm-5 px-5 text-light">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <!--  right section-->
                <div class="bg-gray d-flex flex-column justify-content-center p-4 right-section rounded">
                    <button-action v-if="$root.$can('patient', 'patient-create')" to_name="patientCreate"
                                   title="New Patient"/>
                    <button-action v-if="$root.$can('staff', 'staff-create')" to_name="staffCreate" title="New Staff"/>
                    <button-action v-if="$root.$can('patient', 'patient-view')" to_name="patientList"
                                   title="Existing Patient"/>
                    <button-action v-if="$root.$can('staff', 'staff-view')" to_name="staffList" title="Existing Staff"/>

                </div>
                <!-- logout btn -->
                <div class="col-12 text-center my-5">
                    <div
                        class="align-items-center d-flex flex-column flex-sm-row gap-2 gap-sm-0 justify-content-center mb-2 mb-sm-3">
                        <router-link v-if="$root.$can('role', 'role-view')" :to="{name: 'roleList'}"
                                     class="bg-primary ct-width-btn text-light me-sm-3">Role
                        </router-link>
                        <a v-if="$root.$can('designation', 'designation-view')" @click="designationModal"
                           href="javascript:void(0)"
                           class="bg-primary ct-width-btn text-light me-sm-3"> Designation</a>
                        <designation-create ref="designation_modal" v-if="designation_modal"></designation-create>
                        <button-action class="me-sm-3" v-if="$root.$can('attendance', 'attendance-view')"
                                       to_name="attendanceList"
                                       title="Attendance"/>
                        <button-action v-if="$root.$can('accounts', 'accounts-view')" to_name="accounts"
                                       title="Accounts"/>
                    </div>
                    <a href="javascript:void(0)" @click="logout()" class="bg-primary ct-width-btn text-light me-sm-3 mb-2 mb-sm-0">Logout</a>
                    <a href="javascript:void(0)" class="bg-primary ct-width-btn text-light  me-sm-3 mb-2 mb-sm-0" data-bs-toggle="modal"
                       data-bs-target="#passwordCreateModal">Password</a>
                    <router-link v-if="$root.$can('testimonial', 'testimonial-view')" :to="{name: 'testimonial'}"
                                 class="bg-primary ct-width-btn text-light  me-sm-3 mb-2 mb-sm-0">Testimonial
                    </router-link>

                </div>
            </div>
        </div>
        <password-modal type="dashboard"/>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import * as JwtService     from "@/services/jwt.service";
import TimePicker          from "@/components/picker/TimePicker";
import DatePicker          from "@/components/picker/DatePicker";
import moment              from 'moment'
import DesignationCreate   from "@/views/admin/pages/designation/Create";
import PasswordModal       from "./staff/PasswordModal";

export default {
    name      : "Dashboard",
    components: {PasswordModal, DesignationCreate, TimePicker, DatePicker},
    data      : () => ({
        designation_modal: false,
        form             : {
            date   : moment(new Date()).format("YYYY-MM-DD"),
            log_in : moment(new Date()).format("HH:mm:ss"),
            log_out: '',
        },
        errors           : []
    }),
    computed  : {
        user() {
            return this.$root.user;
        }
    },
    mounted() {
        this.getAttendance();
    },
    methods: {
        designationModal() {
            this.designation_modal  = true;
            const component_created = setInterval(() => {
                if (this.$refs['designation_modal']) {
                    this.$refs['designation_modal'].openModal();
                    clearInterval(component_created);
                }
            }, 100);
        },
        getAttendance() {
            ApiService.get('/attendances/1').then(res => {
                if (res.data) {
                    this.form = res.data.data;
                }
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        addAttendance() {
            let form = {
                ...this.form,
                user_id: this.$root.user.id
            }
            ApiService.post('/attendances', form).then(res => {
                this.errors = []
                this.form   = res.data.data;
                NotificationService.success('Attendance Added');
            }).catch(error => {
                this.errors = error.response.data.errors;
                NotificationService.error(error.response.data.message);
            })
        },
        logout() {
            const token = JwtService.getToken();
            if (typeof token != "undefined") {
                ApiService.post('/logout').then(res => {
                    JwtService.destroyToken();
                    this.$router.push({name: "login"});
                    NotificationService.success(res.data.message);
                }).catch(error => {
                    NotificationService.error(error.response.data.message);
                })
            }
        },
    }
}
</script>
