<template>
    <div>
        <div class="modal fade" id="designation-modal-selector" data-bs-backdrop="static" data-bs-keyboard="false"
             tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5>Designation Add</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="editMode ? update() : save()">
                            <div class="mb-3 row">
                                <label for="name" class="col-sm-3 col-form-label">Designation Name</label>
                                <div class="col-sm-6">
                                    <input required v-model="form.name" type="text" class="form-control mb-3" id="name">
                                </div>
                                <div class="col-sm-3">
                                    <input type="submit" class="btn btn-primary w-100"
                                           :value="editMode ? 'Update' : 'Save'">
                                </div>
                            </div>
                        </form>
                        <div style="height: 400px; overflow-y: scroll">
                            <table class="table table-striped table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Designation</th>
                                    <th scope="col">Edit</th>
                                    <th scope="col">Delete</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-if="lists.length">
                                    <tr v-for="(list, index) in lists">
                                        <th scope="row">{{ table.pagination.from + index }}</th>
                                        <td>{{ list.name }}</td>
                                        <td>
                                            <update-action type="modal" v-if="$root.$can('list', 'list-update')"
                                                           :id="list.id"/>
                                        </td>
                                        <td>
                                            <delete-action v-if="$root.$can('list', 'list-delete')" :id="list.id"/>
                                        </td>
                                    </tr>
                                </template>
                                <template v-if="!lists.length">
                                    <tr>
                                        <th class="text-center font-weight-bolder" colspan="100%">No data found</th>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                        <pagination v-if="lists.length>0" :pagination="table.pagination" @paginate="getList()"
                                    :offset="5"/>
                    </div>
                </div>
            </div>
        </div>
        <a href="javascript:void(0)" class="d-none" id="btn-create-modal" data-bs-toggle="modal"
           data-bs-target="#designation-modal-selector"></a>
    </div>

</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import Pagination          from "@/components/Pagination";

export default {
    name      : "DesignationCreate",
    components: {ApiService, NotificationService, Pagination},
    data      : () => ({
        lists   : [],
        form    : {
            name: ''
        },
        table   : {
            per_page  : 10,
            search    : '',
            pagination: {
                current_page: 1
            }
        },
        editMode: false
    }),
    mounted() {
        this.getList();
    },
    methods: {
        openModal() {
            document.querySelectorAll("#btn-create-modal")[0].click();
        },
        getList() {
            let params = {
                ...this.table,
                page: this.table.pagination.current_page,
            };
            ApiService.get('/designations', {params: params}).then((res) => {
                this.lists            = res.data.data;
                this.table.pagination = res.data.meta;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        save() {
            ApiService.post('/designations', this.form).then((res) => {
                this.getList();
                /*this.lists.unshift(res.data.data);*/
                NotificationService.success(res.data.message);
                this.formReset();
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        edit(id) {
            this.editMode = true;
            ApiService.get(`/designations/${id}`).then((res) => {
                this.form = res.data.data;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        update() {
            ApiService.update(`/designations/${this.form.id}`, this.form).then((res) => {
                NotificationService.success(res.data.message);
                this.formReset();
                let updatedItem        = res.data.data
                let foundIndex         = this.lists.findIndex(x => x.id == updatedItem.id);
                this.lists[foundIndex] = updatedItem;
                this.editMode          = false;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        destroy(id) {
            Swal.fire({
                title             : 'Are you sure?',
                text              : "You won't be able to revert this!",
                icon              : 'warning',
                showCancelButton  : true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor : '#d33',
                confirmButtonText : 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.delete(`/designations/${id}`,).then(res => {
                        this.getList();
                        /*let foundIndex = this.lists.findIndex(x => x.id == id);
                        this.lists.splice(foundIndex, 1);*/
                        NotificationService.success(res.data.message);
                    }).catch(error => {
                        NotificationService.error(error.response.data.message);
                    })
                }
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        formReset() {
            this.form = {
                name: '',
            }
        }
    },
}
</script>

<style scoped>

</style>
