<template>
    <date-picker :class="class_name" value-type="HH:mm:ss" type="time" v-model="$parent[v_type][v_model]" format="hh:mm a"
                 :time-picker-options="options" :placeholder="`Select ${title}`"/>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name      : "TimePicker",
    components: {DatePicker},
    props     : {
        class_name: {default: ''},
        title     : {
            type   : String,
            default: "Time"
        },
        v_model   : {
            type   : String,
            default: "date"
        },
        v_type         : {
            type   : String,
            default: 'form'
        },
    },
    data      : () => ({
        options: {
            start: '06:00',
            step : '00:15',
            end  : '22:59',
        },
    })
}
</script>
